
export const suppoortContainerStyles = {
  ".support-container": {
    margin: `10px auto`,
    display: `flex`,
    flexWrap: `wrap`,
    justifyContent: `center`,
    alignContent: `center`,
    alignItems: `center`,
  }
}

export default suppoortContainerStyles