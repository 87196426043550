/** @jsx jsx */
import { jsx, Container, Button } from 'theme-ui'
import React from "react"
import Modal from 'react-modal';
import suppoortContainerStyles from '../styles/support-container';

export default function SupportMeLinks() {

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0px',
      border: 'none',
      width: '600px',
      maxWidth: '95%',
      height: '600px',
      maxHeight: '70%',
    }
  };

  Modal.setAppElement('#___gatsby');

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const modal = (
    <Container sx={{ ...suppoortContainerStyles }}>
      <div className="support-container">
        <Button type="button" onClick={openModal}>Subscribe</Button>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={() => document.body.style.overflow = 'hidden'}
          onRequestClose={closeModal}
          onAfterClose={() => document.body.style.removeProperty('overflow')}
          style={customStyles}
          shouldFocusAfterRender={false}
        >
          <iframe className="airtable-embed" src="https://airtable.com/embed/shrOr0nQEPk6KeuZO?backgroundColor=red" frameBorder="0" onmousewheel="" width="100%" height="98%" style={{ background: "transparent", border: "1px solid #ccc" }}></iframe>
        </Modal>
      </div>
    </Container>
  );

  return modal;
}
